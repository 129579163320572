import noop from "lodash/noop";
import React, { useMemo } from "react";
import MiniSearch from "minisearch";
import { zIndex } from "@puzzle/utils";
import { AutocompleteMenu, Select, Toolbar } from "@puzzle/ui";
import {
  useCategorizableAccounts,
  CATEGORIZABLE_ACCOUNT_SEARCHABLE_FIELDS,
  CategorizableAccount,
} from "./hooks/useCategorizableAccounts";
import { Footer, StyledButton } from "./CategoriesFilter";

type Props = {
  onChange: (values: CategorizableAccount[]) => void;
  selectedCategories: CategorizableAccount[];
  trigger?: React.ReactElement;
  subMenuTrigger?: string;
  footer?: boolean;
  disabled?: boolean;
};

export const CategorizableAccountsFilter = ({
  onChange,
  selectedCategories,
  trigger,
  subMenuTrigger,
  footer = false,
  disabled = false,
}: Props) => {
  const { categorizableAccounts, categorizableAccountsByDisplayId } = useCategorizableAccounts();

  const miniSearch = useMemo(() => {
    const instance = new MiniSearch<CategorizableAccount>({
      fields: CATEGORIZABLE_ACCOUNT_SEARCHABLE_FIELDS as string[],
      idField: "displayId",
      searchOptions: {
        boost: { displayName: 2, displayId: 1.5, examples: 0.5, description: 0.5 },
        prefix: true,
        fuzzy: 0.2,
      },
    });

    instance.addAll(categorizableAccounts);

    return instance;
  }, [categorizableAccounts]);

  const _trigger = trigger ? (
    trigger
  ) : (
    <Toolbar.Button variant="menu" asChild disabled={disabled}>
      <Select
        variant="filter"
        size="small"
        options={[]}
        placeholder="Categories"
        onSelectionChange={noop}
      />
    </Toolbar.Button>
  );

  return (
    <AutocompleteMenu<CategorizableAccount, true, false, false>
      getOptionLabel={(option) => option.displayName}
      getOptionKey={(o) => o.displayId}
      options={categorizableAccounts}
      value={selectedCategories}
      subMenuTrigger={subMenuTrigger}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      trigger={_trigger}
      label="Pick categories"
      multiple
      css={{ zIndex: zIndex("subMenu") }}
      filterOptions={(options, state) => {
        if (!state.inputValue) {
          return options;
        }
        const res = miniSearch
          .search(state.inputValue)
          .flatMap((result) => categorizableAccountsByDisplayId?.[result.id] ?? []);
        return res;
      }}
      isOptionEqualToValue={(option, value) => option.displayId === value.displayId}
      footer={
        footer ? (
          <Footer>
            <StyledButton
              variant="minimal"
              size="small"
              onClick={() => onChange(categorizableAccounts)}
            >
              Select all
            </StyledButton>
            <StyledButton variant="minimal" size="small" onClick={() => onChange([])}>
              Clear
            </StyledButton>
          </Footer>
        ) : (
          <></>
        )
      }
    />
  );
};
