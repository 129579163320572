import React, { useMemo } from "react";

import { Menu } from "@puzzle/ui";
import { Settings2 } from "@puzzle/icons";

import { Button } from "ve";
import { Box } from "@puzzle/ve";
import { S, veColors as colors } from "@puzzle/theme";

import { ColumnVisibility, TransactionColHeaderMapping, TransactionColId } from "./shared";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { ColumnVisibilitySwitch } from "components/common/ColumnVisibilitySwitch";
import { zIndex } from "@puzzle/utils";

type Props = {
  columnVisibility: ColumnVisibility;
  setColumnVisibility: React.Dispatch<React.SetStateAction<ColumnVisibility | undefined>>;
};

export const ColumnVisibilityMenu = ({ columnVisibility, setColumnVisibility }: Props) => {
  const renderToggleColMenuItem = (colId: TransactionColId) => {
    return (
      <Menu.Item key={colId}>
        <ColumnVisibilitySwitch
          label={TransactionColHeaderMapping[colId]}
          checked={columnVisibility[colId]}
          onCheckedChange={(checked) => {
            setColumnVisibility({ ...columnVisibility, [colId]: checked });
          }}
          draggable
        />
      </Menu.Item>
    );
  };

  const allCols = useMemo(() => {
    const allKeys = Object.keys(TransactionColId);
    if (!isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols)) {
      const keysToExclude: string[] = [
        TransactionColId.department,
        TransactionColId.location,
        TransactionColId.classes,
      ];
      return allKeys.filter((key) => !keysToExclude.includes(key));
    }
    if (isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols)) {
      const keysToExclude: string[] = [TransactionColId.classifications];
      return allKeys.filter((key) => !keysToExclude.includes(key));
    }
    return allKeys;
  }, []);

  return (
    <>
      <Menu
        css={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("menu") : "auto" }}
        trigger={
          <Button
            variant="secondaryAlpha"
            size="miniSquare"
            shape="buttonMoreSquare"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="sr-only">Set column visibility</span>
            <Settings2 size={14} color={colors.white70} style={{ display: "flex" }} />
          </Button>
        }
      >
        <Menu.Group>
          {allCols.map((col) => {
            return renderToggleColMenuItem(col as TransactionColId);
          })}

          <Menu.Separator />
          <Box css={{ padding: `${S.$0h} ${S.$1}`, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="minimal"
              size="small"
              onClick={() => {
                setColumnVisibility({
                  date: true,
                  account: true,
                  vendor_customer: true,
                  descriptor: true,
                  category: true,
                  location: true,
                  department: true,
                  classes: true,
                  classifications: true,
                  status: true,
                  amount: true,
                });
              }}
            >
              Show all
            </Button>
          </Box>
        </Menu.Group>
      </Menu>
    </>
  );
};
