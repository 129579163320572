import React, { ComponentProps } from "react";
import dynamic from "next/dynamic";
import { externalBrandColors } from "@puzzle/theme";

export enum BrandName {
  Amazon = "amazon",
  AmericanExpress = "american-express",
  AngelList = "angellist",
  BankOfAmerica = "bank-of-america",
  BillDotCom = "bill-dot-com",
  Bluevine = "bluevine",
  Brex = "brex",
  Carta = "carta",
  Central = "central",
  Chase = "chase",
  Citi = "citi",
  Daydream = "daydream",
  Deel = "deel",
  ElanCreditCard = "elan-credit-card",
  Every = "every",
  Expensify = "expensify",
  FirstNationalBankOfOmaha = "first-national-bank-of-omaha",
  FirstRepublic = "first-republic",
  Google = "google",
  Gusto = "gusto",
  Justworks = "justworks",
  Ltse = "ltse",
  Meow = "meow",
  Mercury = "mercury",
  Novo = "novo",
  Paypal = "paypal",
  PNC = "pnc",
  Plaid = "plaid",
  Quickbooks = "quickbooks",
  Ramp = "ramp",
  Rho = "rho",
  Relay = "relay",
  Rippling = "rippling",
  Runaway = "runaway",
  Salesforce = "salesforce",
  Stripe = "stripe",
  StripeAtlas = "stripe-atlas",
  SVB = "svb",
  Trinet = "trinet",
  UsBank = "us-bank",
  WellsFargo = "wells-fargo",
  Wise = "wise",
}

export const BRAND_BACKGROUND_COLORS: Record<BrandName, string> = {
  [BrandName.Amazon]: externalBrandColors.amazon.logoBackground,
  [BrandName.AmericanExpress]: externalBrandColors.americanExpress.logoBackground,
  [BrandName.AngelList]: externalBrandColors.angelList.logoBackground,
  [BrandName.BankOfAmerica]: externalBrandColors.bankOfAmerica.logoBackground,
  [BrandName.BillDotCom]: externalBrandColors.billDotCom.orangePantone,
  [BrandName.Bluevine]: externalBrandColors.bluevine.logoBackground,
  [BrandName.Brex]: externalBrandColors.brex.logoBackground,
  [BrandName.Carta]: externalBrandColors.carta.logoBackgroundBrandIcons,
  [BrandName.Central]: externalBrandColors.central.logoBackground,
  [BrandName.Chase]: externalBrandColors.chase.logoBackgroundBrandIcons,
  [BrandName.Citi]: externalBrandColors.citi.logoBackground,
  [BrandName.Daydream]: externalBrandColors.daydream.logoBackground,
  [BrandName.Deel]: externalBrandColors.deel.logoBackground,
  [BrandName.ElanCreditCard]: externalBrandColors.elanCreditCard.logoBackground,
  [BrandName.Every]: externalBrandColors.every.logoBackground,
  [BrandName.Expensify]: externalBrandColors.expensify.logoBackground,
  [BrandName.FirstNationalBankOfOmaha]: externalBrandColors.firstNationalBankOfOmaha.logoBackground,
  [BrandName.FirstRepublic]: externalBrandColors.firstRepublic.logoBackground,
  [BrandName.Google]: externalBrandColors.google.logoBackground,
  [BrandName.Gusto]: externalBrandColors.gusto.logoBackground,
  [BrandName.Justworks]: externalBrandColors.justworks.logoBackground,
  [BrandName.Ltse]: externalBrandColors.ltse.logoBackground,
  [BrandName.Meow]: externalBrandColors.meow.logoBackgroundBrandIcons,
  [BrandName.Mercury]: externalBrandColors.mercury.logoBackground,
  [BrandName.Novo]: externalBrandColors.novo.logoBackground,
  [BrandName.Paypal]: externalBrandColors.paypal.logoBackground,
  [BrandName.PNC]: externalBrandColors.pnc.logoBackground,
  [BrandName.Plaid]: externalBrandColors.plaid.logoBackground,
  [BrandName.Quickbooks]: externalBrandColors.quickbooks.logoBackground,
  [BrandName.Ramp]: externalBrandColors.ramp.logoBackgroundBrandIcons,
  [BrandName.Rho]: externalBrandColors.rho.logoBackground,
  [BrandName.Relay]: externalBrandColors.relay.logoBackground,
  [BrandName.Rippling]: externalBrandColors.rippling.logoBackground,
  [BrandName.Runaway]: externalBrandColors.runway.logoBackground,
  [BrandName.Salesforce]: externalBrandColors.salesforce.logoBackground,
  [BrandName.Stripe]: externalBrandColors.stripe.logoBackgroundBrandIcons,
  [BrandName.StripeAtlas]: externalBrandColors.stripeAtlas.logoBackground,
  [BrandName.SVB]: externalBrandColors.svb.logoBackground,
  [BrandName.Trinet]: externalBrandColors.trinet.logoBackgroundBrandIcons,
  [BrandName.UsBank]: externalBrandColors.usBank.logoBackground,
  [BrandName.WellsFargo]: externalBrandColors.wellsFargo.logoBackground,
  [BrandName.Wise]: externalBrandColors.wise.logoBackground,
};

const SVG_IMPORT_FUNCTION = (icon: BrandName) => () => import(`./svgs/${icon}.svg?svgr`);

export type BrandIconProps = {
  name: BrandName;
} & ComponentProps<"svg">;

// TODO: Add a 'shape' prop defaulting to circular
// if 'square', we'll use the 'width' and 'height' of 48px
const BrandIcon = ({ name, ...props }: BrandIconProps) => {
  const Svg = dynamic(SVG_IMPORT_FUNCTION(name));

  return <Svg data-testid={name} {...props} />;
};

export default BrandIcon;
