import isNil from "lodash/isNil";
import React, { useMemo } from "react";
import Big from "big.js";

import { createMRTColumnHelper } from "data-grid";
import { TagFan } from "ve";
import { Box } from "@puzzle/ve";

import { S } from "@puzzle/theme";
import { Split } from "@puzzle/icons";
import { DateFormatterResult, formatMoney } from "@puzzle/utils";

import { CategoryFragment } from "graphql/types";
import { UpdateCategoryMenu } from "./UpdateField/UpdateCategoryMenu";
import { StatusIcon } from "components/transactions/Cells/StatusIcon";
import { VendorCell } from "components/transactions/Cells/VendorCell";
import { LockedPeriodStatus } from "components/transactions/Cells/LockedPeriodStatus";
import { AvailableDateTooltip, ManualTransactionTooltip } from "./AsteriskTooltip";
import { SelectCategoryInput } from "components/transactions/SelectCategoryInput";
import type { FilterState } from "components/dashboard/Transactions/TransactionsProvider";
import { TransactionStatus } from "./TransactionStatus";
import { AssignedUserAvatar } from "./AssignedUserAvatar";
import { ClassSegment } from "components/common/Classifications/TagEntity";
import { TransactionClassesCell } from "./TransactionClassesCell";
import {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
  useExtraTransactionState,
  useGetTransaction,
} from "./hooks/useSingleTransaction";
import { useEditSplits } from "components/dashboard/Transactions/hooks/useEditSplits";
import { useCategories } from "components/common/hooks/useCategories";
import {
  TransactionRowData,
  TransactionColHeaderMapping,
  TransactionColId,
  SplitRowData,
  StatusRoot,
} from "./shared";
import { CategoriesColumnHeader } from "./CategoriesColumnHeader";
import { DescriptionCell } from "./DescriptionCell";
import { AccountWithInstitutionLogoCell } from "components/transactions/Cells/AccountWithInstitutionLogoCell";

const isSplit = (data?: TransactionRowData): data is SplitRowData => {
  return !isNil((data as SplitRowData).splitIndex);
};

const hasSplits = (data?: TransactionRowData) => {
  return !isSplit(data) && data?.subRows && data?.subRows?.length > 0;
};

const getClassSegments = (transactionRow: TransactionRowData): ClassSegment[] => {
  const isSplitRow = isSplit(transactionRow);
  if (isSplitRow && transactionRow.classSegments.length <= 0) return [];
  if (!isSplitRow && transactionRow.transaction.detail.classSegments.length <= 0) return [];
  const interimEntity = isSplitRow ? transactionRow : transactionRow.transaction.detail;

  return interimEntity.classSegments;
};

const EditSplitCategoryCell = ({
  data,
  wrap,
}: {
  data: Pick<SplitRowData, "transactionId" | "detailId" | "splitIndex">;
  wrap?: boolean;
}) => {
  const transactionResult = useGetTransaction(data.transactionId);
  const transaction = transactionResult.data?.transaction;
  const { canRecategorizeSplit } = useExtraTransactionState(transaction);
  const { updateSplitCategory, persistSplits, getSplit } = useEditSplits(transaction);
  const { categories } = useCategories();
  const handleUpdate = (c: CategoryFragment) => {
    const newSplits = updateSplitCategory(data.detailId, c);
    persistSplits(newSplits);
  };

  const split = getSplit(data.detailId) || transaction?.splits[data.splitIndex];
  if (!split) {
    return null;
  }

  return (
    <SelectCategoryInput
      value={split.category}
      categories={categories}
      canEdit={canRecategorizeSplit(split)}
      onChange={handleUpdate}
      wrap={wrap}
      isBillPayment={!!split.isBillPayment}
      isInvoicePayment={!!split.isInvoicePayment}
    />
  );
};

const columnHelper = createMRTColumnHelper<TransactionRowData>();

// Source column
export const accountColumn = columnHelper.accessor((row) => row.account, {
  id: TransactionColId.account,
  size: 180,
  header: TransactionColHeaderMapping.account,
  enableHiding: true,
  enableSorting: false, // Sorting by this column is not supported until BE work is done
  meta: {
    align: "left",
    getCellProps: () => ({
      muted: true,
    }),
  },
  Cell: (info) => {
    if (isSplit(info.row.original)) {
      return null;
    }

    const account = info?.cell?.getValue();

    return <AccountWithInstitutionLogoCell account={account} />;
  },
});

export const vendorCustomerColumn = columnHelper.accessor((row) => row.transaction.detail, {
  id: TransactionColId.vendor_customer,
  size: 180,
  header: TransactionColHeaderMapping.vendor_customer,
  sortDescFirst: false,
  enableSorting: true,
  Cell: ({ row }) => {
    const detail = row.original.transaction.detail;
    if (detail.category.__typename !== "LedgerCategory") {
      throw new Error("Wrong category type");
    }
    const isRevenueTransaction = detail.category.defaultCashlike === "REVENUE";

    return (
      <VendorCell
        transaction={row.original.transaction}
        vendor={detail.vendor}
        customer={detail.customer}
        isRevenueTransaction={isRevenueTransaction}
      />
    );
  },
});

const DESC_COL_WIDTH = 240;
const DESC_PADDING = 24;
export const descriptionColumn = columnHelper.accessor((row) => row.descriptor, {
  id: TransactionColId.descriptor,
  enableHiding: true,
  size: DESC_COL_WIDTH,
  maxSize: DESC_COL_WIDTH,
  enableSorting: true,
  sortDescFirst: false,
  meta: {
    getHeaderProps: () => ({
      css: { paddingLeft: `${DESC_PADDING}px !important` },
    }),
    getCellProps: () => ({
      css: { paddingLeft: `${DESC_PADDING}px !important` },
    }),
  },
  header: TransactionColHeaderMapping.descriptor,
  Cell: ({ row: { original }, cell }) => {
    const originalDescriptor = cell.getValue();
    const updatedDescriptor = original.transaction.detail.descriptor;
    const descriptor = updatedDescriptor ?? originalDescriptor;
    const amount = original.amount;
    const isNegative = Big(amount).lte(0);
    const shouldDisplayAsSplit = isSplit(original);
    return (
      <DescriptionCell
        colWidth={DESC_COL_WIDTH}
        paddingWidth={DESC_PADDING}
        isSplit={shouldDisplayAsSplit}
        isNegative={isNegative}
        text={descriptor}
      />
    );
  },
});

export const amountColumn = columnHelper.accessor((row) => row.amount, {
  id: TransactionColId.amount,
  enableHiding: true,
  size: 150,
  meta: {
    type: "number",
    align: "right",
    getCellProps: (row: any) => ({
      css: {
        color: isSplit(row.original) ? "$gray500" : "inherit",
      },
    }),
  },
  header: "Amount",
  // The following amount of pixel-pushing is necessary to match the design when right-aligned
  Header: <div style={{ textAlign: "right", marginLeft: 6, marginRight: 18 }}>Amount</div>,
  Cell: ({ cell, row: { original } }) => {
    const formatted = formatMoney({ currency: "USD", amount: cell.getValue() });

    return (
      <Box css={{ marginRight: S.$1h }}>
        {formatted}
        <ManualTransactionTooltip integrationType={original.transaction.integrationType} />
      </Box>
    );
  },
});

export const statusColumn = columnHelper.accessor((row) => row, {
  id: TransactionColId.status,
  enableHiding: true,
  enableSorting: false, // Sorting by this column is not supported until BE work is done
  size: 120,
  header: TransactionColHeaderMapping.status,
  Cell: ({ row: { original }, cell }) => {
    const { transaction } = cell.getValue();

    if (isSplit(original)) return null;

    return (
      <StatusRoot>
        <StatusIcon
          transaction={transaction}
          onClick={(e) => {
            e.stopPropagation();
          }}
          tooltipProps={{
            side: "bottom",
            align: "start",
            alignOffset: 8,
            arrow: false,
            sideOffset: 14,
          }}
          location="transactionTable"
        />
        <TransactionStatus transaction={transaction} />
      </StatusRoot>
    );
  },
});

export const assigneeColumn = columnHelper.accessor((row) => row.transactionId, {
  id: "assignee",
  size: 50,
  enableColumnOrdering: false, // this and the helper column are always the last two columns on the right
  enableSorting: false, // Sorting by this column is not supported until BE work is done
  header: "", // this was undefined before
  Cell: ({ row: { original }, cell }) => {
    if (isSplit(original)) {
      return null;
    }

    return <AssignedUserAvatar size="mini" id={cell.getValue()} />;
  },
});

const CLASSIFICATIONS_MIN_SIZE = 240;

export const classificationsColumn = columnHelper.display({
  id: "classifications",

  minSize: CLASSIFICATIONS_MIN_SIZE,
  grow: false,
  enableHiding: true,
  meta: {
    align: "left",
  },
  header: "Classifications",
  enableSorting: false, // Sorting by this column is not supported until BE work is done

  Cell: ({ row: { original } }) => {
    const classSegments = isSplit(original)
      ? original.classSegments
      : original.transaction.detail.classSegments;

    const tags = useMemo(() => {
      const CLASSIFICATIONS_PADDING = 16;
      const tagDivMaxWidth = CLASSIFICATIONS_MIN_SIZE - CLASSIFICATIONS_PADDING;
      // the maxWidth is set to the number of tags that can fit in the tagDivMaxWidth
      const maxWidth = classSegments.length
        ? Math.floor(tagDivMaxWidth / classSegments.length)
        : tagDivMaxWidth;
      return (
        classSegments
          .map((segment) => ({
            id: segment.id,
            name: segment.name,
            group: segment.reportingClass.name,
            hoveredIdx: null,
            maxWidth,
            width: "auto" as const,
            originalWidth: null,
            transition: "none",
          }))
          // order from shortest to longest tag name for better UX: to not bury short tags under long tags.
          // We will use flex: reverse-row to display them in the correct order
          .sort((a, b) => a.name.length - b.name.length)
      );
    }, [classSegments]);

    if (classSegments.length === 0) return null;

    return <TagFan tags={tags} outerDivSize={CLASSIFICATIONS_MIN_SIZE} />;
  },
});

export const departmentColumn = columnHelper.display({
  id: TransactionColId.department,
  size: 120,
  enableHiding: true,
  enableSorting: false, // Sorting by this column is not supported until BE work is done
  meta: {
    align: "left",
  },
  header: TransactionColHeaderMapping.department,
  Cell: ({ row: { original } }) => {
    const classSegments = getClassSegments(original);
    if (classSegments.length <= 0) return null;

    const departmentTags = classSegments.filter(
      (segment) => segment.reportingClass.type === "Department"
    );

    return (
      <TransactionClassesCell key={TransactionColId.department} classSegments={departmentTags} />
    );
  },
});

export const locationColumn = columnHelper.display({
  id: TransactionColId.location,
  size: 120,
  enableHiding: true,
  enableSorting: false, // Sorting by this column is not supported until BE work is done
  meta: {
    align: "left",
  },
  header: TransactionColHeaderMapping.location,
  Cell: ({ row: { original } }) => {
    const classSegments = getClassSegments(original);
    if (classSegments.length <= 0) return null;

    const locationTags = classSegments.filter(
      (segment) => segment.reportingClass.type === "Location"
    );

    return <TransactionClassesCell key={TransactionColId.location} classSegments={locationTags} />;
  },
});

export const classesColumn = columnHelper.display({
  id: TransactionColId.classes,
  size: 120,
  enableHiding: true,
  enableSorting: false, // Sorting by this column is not supported until BE work is done
  meta: {
    align: "left",
  },
  header: TransactionColHeaderMapping.classes,
  Cell: ({ row: { original } }) => {
    const classSegments = getClassSegments(original);
    if (classSegments.length <= 0) return null;

    const customTags = classSegments.filter(
      (segment) => segment.reportingClass.type === "UserCreated"
    );

    return <TransactionClassesCell key={TransactionColId.classes} classSegments={customTags} />;
  },
});

export enum SortByColumnOptions {
  AscFirst = "AscFirst",
  DescFirst = "DescFirst",
}

export type DateColumnOptions = {
  sortBy?: SortByColumnOptions;
};

export const dateColumn = (
  filter: FilterState,
  dateFormatter: DateFormatterResult,
  options: DateColumnOptions = {}
) =>
  columnHelper.accessor((row) => (filter.showAvailableDate && row.availableOn) || row.date, {
    sortDescFirst: options.sortBy === SortByColumnOptions.DescFirst,
    id: TransactionColId.date,
    size: 130,
    header: TransactionColHeaderMapping.date,
    enableHiding: true,
    meta: {
      getCellProps: () => ({
        muted: true,
      }),
    },
    Cell: ({ row, cell }) => {
      const transaction = row.original?.transaction;
      if (!transaction || isSplit(row.original)) {
        return null;
      }

      const date = cell.getValue();

      return (
        <StatusRoot>
          <div>
            {dateFormatter.format(date)}

            {filter.showAvailableDate && row.original.availableOn && <AvailableDateTooltip />}
          </div>

          <LockedPeriodStatus transaction={transaction} onClick={(e) => e.stopPropagation()} />
        </StatusRoot>
      );
    },
  });

export const categoryColumn = (categories: CategoryFragment[]) =>
  columnHelper.accessor((row) => row.category, {
    id: TransactionColId.category,
    size: 240,
    header: "Category",
    // We are enabling actions, but injecting our own CategoriesColumnHeader component
    // This allows us to use sorting and reordering as provided by MRT
    // And we are able to inject our own filter component for the column actions
    enableColumnActions: true,
    enableSorting: false, // Sorting by this column is not supported until BE work is done
    muiColumnActionsButtonProps: {
      "aria-label": "Filter by category",
      role: "menu",
      title: "Filter by category",
      style: { float: "left" },
      onClick: (e) => {
        e.stopPropagation(); // stop default actions menu from sometimes opening
      },
      children: <CategoriesColumnHeader />,
    },
    Cell: ({ row: { original } }) => {
      if (!original) {
        return null;
      }

      if (hasSplits(original)) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Split style={{ marginRight: 8 }} />
          </div>
        );
      }

      if (isSplit(original)) {
        return <EditSplitCategoryCell data={original} />;
      }

      return (
        <UpdateCategoryMenu
          categories={categories}
          id={original.transactionId}
          metrics={{
            location: UpdateCategoryMetricsLocations.TransactionsTable,
            component: UpdateCategoryMetricsView.CategoryModal,
          }}
        />
      );
    },
  });
