import React from "react";
import { formatAccountName, zIndex } from "@puzzle/utils";
import { AutocompleteMenu } from "@puzzle/ui";
import { ClassFilterOption } from "./useClassificationsFilter";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

type ClassificationsFilterProps = {
  onClassificationsChange: (values: ClassFilterOption[]) => void;
  selectedClassFilters: ClassFilterOption[];
  classificationOptions: ClassFilterOption[];
};
export const ClassificationsFilter = ({
  onClassificationsChange,
  selectedClassFilters,
  classificationOptions,
}: ClassificationsFilterProps) => {
  return (
    <AutocompleteMenu<ClassFilterOption, true, false, false>
      data-testid="classifications_filter"
      getOptionLabel={(option) => formatAccountName(option)}
      getOptionKey={(option) => option.id}
      groupBy={(option) => option.parentName}
      options={classificationOptions}
      value={selectedClassFilters}
      onChange={(e, newValue) => {
        onClassificationsChange(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      subMenuTrigger="Classifications"
      multiple
      css={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("subMenu") : "auto" }}
    />
  );
};
