import React from "react";
import * as Stitches from "@stitches/react";
import { Crown } from "@puzzle/icons";
import { Button } from "@puzzle/ui";

import { usePlansPricingModal } from "components/monetization/PlansPricingModal/usePlansPricingModal";

type UpgradeButtonProps = {
  title?: string;
  onClick?: () => void;
  css?: Stitches.CSS;
  showIcon?: boolean;
  variant?: "gold" | "gold-outline";
};

export const UpgradeButton = ({
  onClick,
  css,
  title,
  variant = "gold",
  showIcon = true,
}: UpgradeButtonProps) => {
  const { showUpgradeModal } = usePlansPricingModal();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    showUpgradeModal();
  };

  return (
    <Button
      prefix={showIcon ? <Crown css={{ marginRight: "$1" }} /> : undefined}
      onClick={handleClick}
      css={css}
      variant={variant}
    >
      {title || "Upgrade"}
    </Button>
  );
};
