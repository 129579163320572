import React from "react";

import { FilterList } from "@puzzle/icons";
import { Text } from "@puzzle/ui";
import { CategoryFragment } from "graphql/types";

import { useTransactionsPage } from "./TransactionsProvider";
import { CategoriesFilter } from "components/common/CategoriesFilter";
import { CategorizableAccountsFilter } from "components/common/CategorizableAccountsFilter";
import { CategorizableAccount } from "components/common/hooks/useCategorizableAccounts";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

export const CategoriesColumnHeader = () => {
  const { selectedCategories, selectedCategorizableAccounts, setFilter } = useTransactionsPage();

  if (isPosthogFeatureFlagEnabled(FeatureFlag.GroupCategoriesByAccount)) {
    return (
      <CategorizableAccountsFilter
        trigger={
          <div>
            <FilterList height={14} width={16} style={{ marginTop: 11 }} />
            {selectedCategorizableAccounts.length > 0 && (
              <Text css={{ fontSize: "10px" }}>{selectedCategorizableAccounts.length}</Text>
            )}
          </div>
        }
        onChange={(values: CategorizableAccount[]) => {
          setFilter({
            ledgerAccountDisplayIds: values.map((c) => c.displayId),
          });
        }}
        selectedCategories={selectedCategorizableAccounts}
        footer
      />
    );
  }
  return (
    <CategoriesFilter
      trigger={
        <div>
          <FilterList height={14} width={16} style={{ marginTop: 11 }} />
          {selectedCategories.length > 0 && (
            <Text css={{ fontSize: "10px" }}>{selectedCategories.length}</Text>
          )}
        </div>
      }
      onCategoriesChange={(values: CategoryFragment[]) => {
        setFilter({
          ledgerCoaKeys: values.map((c) => c.permaKey),
        });
      }}
      selectedCategories={selectedCategories}
      footer
    />
  );
};
