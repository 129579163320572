import { BrandName } from "@puzzle/icons";

export type InstitutionLogoResolver = (company: string) => boolean;

const InstitutionLogoResolversCatalog: Record<BrandName, InstitutionLogoResolver> = {
  [BrandName.Amazon]: (name) => /(\bamazon\b|\baws\b)/i.test(name),
  [BrandName.AmericanExpress]: (name) => /(\bamerican\s+express\b|\bamex\b)/i.test(name),
  [BrandName.AngelList]: (name) => /(angel\s+list|angellist)/i.test(name),
  [BrandName.BankOfAmerica]: (name) => /(\bbank\s+of\s+america\b|^boa$|^bofa$)/i.test(name),
  [BrandName.BillDotCom]: (name) => /(\bbill\b|\bbill.com\b)/i.test(name),
  [BrandName.Bluevine]: (name) => /(\bbluevine\b)/i.test(name),
  [BrandName.Brex]: (name) => /(\bbrex\b)/i.test(name),
  [BrandName.Carta]: (name) => /\bcarta\b/i.test(name),
  [BrandName.Central]: (name) => /(^central$|\bcentral\s+bank\b)/i.test(name),
  [BrandName.Chase]: (name) => /\bchase\b/i.test(name),
  [BrandName.Citi]: (name) => /(^citi$|\bcitibank\b|\bciti\s+bank\b)/i.test(name),
  [BrandName.Daydream]: (name) => /\bdaydream\b/i.test(name),
  [BrandName.Deel]: (name) => /(\bdeel\b|\bdeel.com\b)/i.test(name),
  [BrandName.ElanCreditCard]: (name) => /(\belan\b)/i.test(name),
  [BrandName.Every]: (name) => /(\bevery\b)/i.test(name),
  [BrandName.Expensify]: (name) => /(\bexpensify\b)/i.test(name),
  [BrandName.FirstNationalBankOfOmaha]: (name) =>
    /(\bfirst\s+national\s+bank\s+of\s+omaha\b|\bfnbo\b)/i.test(name),
  [BrandName.FirstRepublic]: (name) => /(\bfirst\s+republic\b)/i.test(name),
  [BrandName.Google]: (name) => /(\bgoogle\b)/i.test(name),
  [BrandName.Gusto]: (name) => /(\bgusto\b)/i.test(name),
  [BrandName.Justworks]: (name) => /(\bjustworks\b)/i.test(name),
  [BrandName.Ltse]: (name) => /(\bltse\b|\blong\s+term\s+stock\s+exchange\b)/i.test(name),
  [BrandName.Meow]: (name) => /(\bmeow\b)/i.test(name),
  [BrandName.Mercury]: (name) => /(\bmercury\b)/i.test(name),
  [BrandName.Novo]: (name) => /(\bnovo\b)/i.test(name),
  [BrandName.Paypal]: (name) => /(\bpaypal\b)/i.test(name),
  [BrandName.PNC]: (name) => /(\bpnc\b)/i.test(name),
  [BrandName.Plaid]: (name) => /(\bplaid\b)/i.test(name),
  [BrandName.Quickbooks]: (name) => /(\bquickbooks\b)/i.test(name),
  [BrandName.Ramp]: (name) => /(\bramp\b)/i.test(name),
  [BrandName.Relay]: (name) => /(\brelay\b)/i.test(name),
  [BrandName.Rho]: (name) => /(\brho\b)/i.test(name),
  [BrandName.Rippling]: (name) => /(\brippling\b)/i.test(name),
  [BrandName.Runaway]: (name) => /(\brunaway\b)/i.test(name),
  [BrandName.Salesforce]: (name) => /(\bsalesforce\b)/i.test(name),
  [BrandName.Stripe]: (name) => /(\bstripe\b)/i.test(name),
  [BrandName.StripeAtlas]: (name) => /(\bstripe\s+atlas\b)/i.test(name),
  [BrandName.SVB]: (name) => /(\bsvb\b|\bsvbfg\b)/i.test(name),
  [BrandName.Trinet]: (name) => /(\btrinet\b)/i.test(name),
  [BrandName.UsBank]: (name) => /(\bu.s.\s+bank\b|\bus\s+bank\b)/i.test(name),
  [BrandName.WellsFargo]: (name) => /(\bwells\s+fargo\b)/i.test(name),
  [BrandName.Wise]: (name) => /(\bwise\b|\bwise.com\b)/i.test(name),
};

export const institutionLogoResolver = (institutionName: string): BrandName | null => {
  const entries = Object.entries(InstitutionLogoResolversCatalog) as [
    BrandName,
    InstitutionLogoResolver,
  ][];

  if (!institutionName) {
    return null;
  }

  for (let i = 0; i < entries.length; i++) {
    const [brandName, resolver] = entries[i];

    if (resolver(institutionName)) {
      return brandName;
    }
  }

  return null;
};
