import { defaultFilter, FilterState } from "components/dashboard/Transactions/TransactionsProvider";
import { CategoryFragment } from "graphql/types";
import { cloneDeep, isEqual, sortBy } from "lodash";

export const HighPriorityCoaKeys = [
  "transfer_account",
  "processor_transfers",
  "preferred_stock",
  "venture_safe_note",
  "equity",
  "miscellaneous_income",
  "miscellaneous_expenses",
  "other_revenue",
  "other_expenses",
  "income",
  "uncategorized_expenses",
  "uncategorized_reimbursements",
  "no_category",
];

export const HighPriorityMinAmount = 500;
export const HighPriorityMaxAmount = null;

export const isHighPriorityReview = (
  filter: FilterState,
  categoriesByPermaKey: Partial<Record<string, CategoryFragment>> | undefined
): boolean => {
  const expected = cloneDeep(defaultFilter);
  expected.ledgerCoaKeys = HighPriorityCoaKeys;
  expected.minAmount = HighPriorityMinAmount;
  expected.maxAmount = HighPriorityMaxAmount;
  if (categoriesByPermaKey) {
    // add only categories that exist on the companies coa
    expected.ledgerCoaKeys = expected.ledgerCoaKeys.filter((c) => !!categoriesByPermaKey[c]);
  }
  const current = cloneDeep(filter);
  current.ledgerCoaKeys = sortBy(current.ledgerCoaKeys);
  expected.ledgerCoaKeys = sortBy(expected.ledgerCoaKeys);
  return isEqual(expected, current);
};

export const getHighPriorityFilters = (
  isApplying: boolean,
  categoriesByPermaKey: Partial<Record<string, CategoryFragment>> | undefined
) => {
  const filter = cloneDeep(defaultFilter);
  if (!isApplying) {
    return filter;
  }
  filter.minAmount = HighPriorityMinAmount;
  filter.maxAmount = HighPriorityMaxAmount;
  filter.ledgerCoaKeys = HighPriorityCoaKeys;
  if (categoriesByPermaKey) {
    // add only categories that exist on the companies coa
    filter.ledgerCoaKeys = filter.ledgerCoaKeys.filter((c) => !!categoriesByPermaKey[c]);
  }

  return filter;
};

export const getHighPriorityFiltersAsQueryParams = (
  categoriesByPermaKey: Partial<Record<string, CategoryFragment>> | undefined
): string => {
  let queryString = `minAmount=${HighPriorityMinAmount}&pageSize=25`;
  if (categoriesByPermaKey) {
    // Add only categories that exist on the company's COA
    const companyCoaKeys = HighPriorityCoaKeys.filter((c) => !!categoriesByPermaKey[c]);
    queryString += `&ledgerCoaKeys=${companyCoaKeys.join(".")}`;
  }
  return queryString;
};
