import groupBy from "lodash/groupBy";
import pickBy from "lodash/pickBy";
import keys from "lodash/keys";
import keyBy from "lodash/keyBy";
import React, { useMemo, useRef, useState } from "react";
import MiniSearch from "minisearch";

import { Input, useAutocomplete, AutocompleteProps, SearchHighlight } from "@puzzle/ui";
import { shadows, styled } from "@puzzle/theme";
import { Search } from "@puzzle/icons";
import { capitalize, capitalizeName } from "@puzzle/utils";

import { CategoryFragment, LedgerAccountType } from "graphql/types";

type Dictionary<T> = {
  [index: string]: T;
};

export const SearchContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: 500,
});

export const Body = styled("div", {
  textStyle: "$bodyXS",
  overflowY: "none !important",
});

export const OptionContainer = styled("div", {
  marginTop: "$2",
  flex: 1,
  overflow: "hidden",
});

export const OptionList = styled("ul", {
  height: "100%",
  // Tried to use ScrollArea but need  to figure out keyboard scrolling
  // https://www.radix-ui.com/docs/primitives/components/scroll-area
  overflowY: "overlay",
  display: "flex",
  flexDirection: "column",
  background: "none",
  boxShadow: "none",
  position: "relative",
  width: "100%",
  whiteSpace: "break-spaces",
  gap: "$2",
  listStyle: "none",
  margin: 0,
  padding: "2px $3 $2",
});

export const CategoryOptionRow = styled("li", {
  border: "1px solid #44445C",
  borderRadius: "$1",
  padding: "$1 $2",
  cursor: "pointer",
  boxShadow: shadows.green600BlurNothing,

  "&[aria-selected=false]": {
    "&:active, &:focus": {
      background: "rgba(255, 255, 255, 0.04)",
      borderColor: "#6C6C84",
    },

    "&:hover, &[data-focus=true], &.Mui-focused": {
      background: "rgba(255, 255, 255, 0.04)",
    },
  },

  "&[aria-selected=true]": {
    borderColor: "transparent",
    boxShadow: shadows.green600BlurSmall,
  },
});

export const CategoryHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  marginBottom: "$1",
  color: "$white",
  fontSize: "14px",
});

export const CategoryInfoHeader = styled("div", {
  textTransform: "uppercase",
  color: "$gray300",
});

export const CategoryInfoText = styled("div", {
  minWidth: "0",
  minHeight: "0",
  color: "$gray400",
});

export const CategoryInfoGrid = styled("div", {
  display: "grid",
  gridGap: "$0h $1h",
  gridTemplateColumns: "1fr 3fr",
  gridTemplateRows: "min-content minmax(0px, max-content)",
  alignItems: "flex-start",
});

export const ModalSearchBar = styled(Input, {
  margin: "$2 $3 0",
  width: "auto",
});

export const StyledSearchHighlight = styled(SearchHighlight, {
  mark: {
    color: "$white",
    backgroundColor: "$purple600",
  },
});

const SEARCHABLE_FIELDS: readonly (keyof CategoryFragment)[] = [
  "name",
  "coaDisplayId",
  "examples",
  "description",
] as const;

export const CategorySearch = ({
  categories,
  initialValue,
  onChange,
  onKeyDown,
  open = true,
  onClose,
  categoryTypes,
}: {
  onKeyDown?: (event: React.KeyboardEvent) => void;
  categories: CategoryFragment[];
  categoryTypes?: Dictionary<{
    id: string;
    type: LedgerAccountType;
  }>;
  initialValue?: CategoryFragment;
  onChange: (val: CategoryFragment) => void;
  open?: boolean;
} & Pick<AutocompleteProps<CategoryFragment>, "onClose">) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const duplicateCategories = useMemo(
    () => keys(pickBy(groupBy(categories, "coaDisplayId"), (c) => c.length > 1)),
    [categories]
  );
  // Hide deprecated categories
  const allOptions = useMemo(
    () => categories.filter((category) => !category.deprecated),
    [categories]
  );
  const categoriesByPermaKey = useMemo(() => {
    return keyBy(allOptions, "coaKey");
  }, [allOptions]);

  // TODO share this with ExtendedFilter?
  const miniSearch = useMemo(() => {
    const instance = new MiniSearch<CategoryFragment>({
      fields: SEARCHABLE_FIELDS as string[],
      idField: "coaKey",
      searchOptions: {
        boost: { name: 2, coaDisplayId: 1.5, examples: 1.3, description: 1 },
        prefix: true,
        fuzzy: 0.2,
      },
    });

    instance.addAllAsync(allOptions);

    return instance;
  }, [allOptions]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete<CategoryFragment>({
      id: "select-category",
      options: allOptions,
      value: inputValue || !initialValue ? null : initialValue,
      inputValue,
      onInputChange: (event, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
        }
      },
      openOnFocus: true,
      clearOnEscape: true,
      autoHighlight: true,
      autoSelect: false,
      open,
      onClose,
      getOptionLabel: (val) => val.name,
      getOptionKey: (val) => `${val.name}-${val.coaKey}`,
      isOptionEqualToValue: (option, value) => {
        return option.permaKey === value.permaKey;
      },
      onChange: (e, newValue) => {
        if (newValue) {
          onChange(newValue);
          setInputValue("");
        }
      },
      filterOptions: (options, state) => {
        const searchInput = state.inputValue;
        if (!searchInput) {
          return options;
        }

        const exactPhrases = searchInput
          .match(/"(.*?)"/g)
          ?.map((x) => x.toLowerCase().replace(/[^\w ]/g, ""));

        const searchResults = miniSearch.search(searchInput);

        return searchResults
          .map((result) => categoriesByPermaKey[result.id])
          .filter((item) => {
            if (exactPhrases && exactPhrases.length > 0) {
              const fieldValues = SEARCHABLE_FIELDS.map(
                (key) =>
                  item[key]
                    ?.toString()
                    .toLowerCase()
                    .replace(/[^\w ]/g, "") || ""
              );
              return exactPhrases.every((phrase) =>
                fieldValues.some((value) => value.includes(phrase))
              );
            }

            return item;
          });
      },
    });

  const options = groupedOptions as CategoryFragment[];

  return (
    <Body ref={containerRef}>
      <SearchContainer {...getRootProps()}>
        <ModalSearchBar
          {...getInputProps()}
          onMouseDown={(e) => e.preventDefault()}
          autoFocus
          onKeyDown={onKeyDown}
          size="mini"
          suffix={<Search />}
          clearable
        />

        <OptionContainer>
          {useMemo(
            () =>
              options.length > 0 ? (
                <OptionList {...getListboxProps()}>
                  {options.map((option, index) => {
                    const prefix =
                      option.coaDisplayId && categoryTypes && categoryTypes[option.coaDisplayId]
                        ? `[${capitalize(
                            categoryTypes[option.coaDisplayId].type.replaceAll("_", " ")
                          )}] `
                        : "";
                    const isDuplicate = duplicateCategories.includes(option.coaDisplayId!);
                    const suffix = isDuplicate
                      ? ` (${capitalizeName(option.coaKey!.replaceAll("_", " "))})`
                      : "";
                    const title = prefix + option.name + suffix;
                    const props = { ...getOptionProps({ option, index }) };
                    return (
                      <CategoryOptionRow id={option.permaKey} {...props} key={props.key}>
                        <CategoryHeader>
                          <StyledSearchHighlight text={title} query={inputValue} />
                        </CategoryHeader>
                        <CategoryInfoGrid>
                          <CategoryInfoHeader>Code</CategoryInfoHeader>
                          {option?.coaDisplayId ? (
                            <CategoryInfoText>{option.coaDisplayId}</CategoryInfoText>
                          ) : (
                            "-"
                          )}

                          <CategoryInfoHeader>Description</CategoryInfoHeader>
                          <CategoryInfoText>
                            <StyledSearchHighlight
                              text={option.description ?? ""}
                              query={inputValue}
                            />
                          </CategoryInfoText>

                          <CategoryInfoHeader>Examples</CategoryInfoHeader>
                          <CategoryInfoText>
                            <StyledSearchHighlight
                              text={option.examples ?? ""}
                              query={inputValue}
                            />
                          </CategoryInfoText>
                        </CategoryInfoGrid>
                      </CategoryOptionRow>
                    );
                  })}
                </OptionList>
              ) : null,
            [
              categoryTypes,
              duplicateCategories,
              getListboxProps,
              getOptionProps,
              inputValue,
              options,
            ]
          )}
        </OptionContainer>
      </SearchContainer>
    </Body>
  );
};
