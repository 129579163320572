import { Button, Dialog, useToasts, Text, DateRangePicker } from "@puzzle/ui";
import { endOfMonth, parseDate, today } from "@puzzle/utils";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { MonthlyExpensesExceededBannerInline } from "components/featureGate/MonthlyExpensesExceededBanner";
import { useExpensesExceededFeatureGate } from "components/featureGate/useExpensesExceededFeatureGate";
import Analytics from "lib/analytics/analytics";
import React, { useCallback } from "react";
import { StyledToolbar } from "../common";
import {
  ReportsPageAccountFragment,
  useTransactionsReportLazyQuery,
} from "../graphql.generated";
import { useReportFilter } from "../useReportFilter";
import { Box } from "@puzzle/ve";
import { S } from "@puzzle/theme";
import { inputBlock, inputLabel } from "../Reports.css";
import { useAsyncReportDownloadComplete } from "components/common/files/useFile";

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  account?: ReportsPageAccountFragment;
};

export const TransactionReportExportModal = ({
  open,
  onConfirm,
  onCancel,
  title,
  account,
}: Props) => {
  const { toast } = useToasts();
  const { company, timeZone } = useActiveCompany<true>();
  const { filters, setFilters } = useReportFilter();
  const { anyDatesExpenseGated } = useExpensesExceededFeatureGate();
  const isExpensesExceeded = anyDatesExpenseGated([filters.start, filters.end]);
  const { onAsyncReportDownloadComplete } = useAsyncReportDownloadComplete();

  const [getTransactionsReport, { loading }] = useTransactionsReportLazyQuery({
    onCompleted: (data) => {
      if (data?.transactionsReport) {
        onAsyncReportDownloadComplete({
          result: data.transactionsReport,
          reportType: "TransactionReport",
        });
        onConfirm();
      } else {
        toast({
          status: "error",
          message:
            "Your report failed to generate. Please try again. If the issue persists, please narrow your query or contact support.",
        });
      }
    },
  });

  const getCsv = useCallback(() => {
    getTransactionsReport({
      variables: {
        input: {
          companyId: company.id,
          accountId: account?.id,
          range: {
            start: filters.start.toString(),
            end: endOfMonth(parseDate(filters.end.toString())).toString(),
            timePeriodKey: "month",
          },
        },
      },
    });
  }, [getTransactionsReport, filters, company, account]);
  return (
    <Dialog open={open} size="small" onOpenChange={onCancel}>
      <Dialog.Title>Export {title} report</Dialog.Title>
      <Dialog.Body css={{ padding: 0 }}>
        <div className={inputBlock}>
          <div className={inputLabel}>Export format</div>
          <Text variant="bodyS" color="$gray100">
            CSV
          </Text>
        </div>
        <div className={inputBlock}>
          <div className={inputLabel}>Set period range</div>
          <StyledToolbar>
            <DateRangePicker
              value={[filters.start, filters.end]}
              onChange={([s, e]) => {
                setFilters({ start: s, end: e });
              }}
              maxDate={today(timeZone)}
              view={"month"}
              groupBy={"month"}
              showLabel={false}
              dark={false}
            />
          </StyledToolbar>
        </div>
        {isExpensesExceeded && (
          <Box css={{ paddingLeft: S["3"], paddingRight: S["3"], paddingTop: S["3"] }}>
            <MonthlyExpensesExceededBannerInline subtext="download reports for the selected period" />
          </Box>
        )}
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={onCancel} autoFocus>
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="primary"
            onClick={() => {
              Analytics.reportModalExportClicked({
                reportType: "TransactionReport",
                startDate: filters.start.toString(),
                endDate: filters.end.toString(),
              });
              getCsv();
            }}
            disabled={loading || isExpensesExceeded}
          >
            Export
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
