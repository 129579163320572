import React, { useMemo, useRef, useState } from "react";
import MiniSearch from "minisearch";

import { useAutocomplete, AutocompleteProps } from "@puzzle/ui";
import { Search } from "@puzzle/icons";

import { CategoryFragment } from "graphql/types";
import {
  useCategorizableAccounts,
  CATEGORIZABLE_ACCOUNT_ID_FIELD,
  CATEGORIZABLE_ACCOUNT_SEARCHABLE_FIELDS,
  CategorizableAccount,
} from "components/common/hooks/useCategorizableAccounts";
import { capitalize } from "@puzzle/utils";
import {
  Body,
  CategoryHeader,
  CategoryInfoGrid,
  CategoryInfoHeader,
  CategoryInfoText,
  CategoryOptionRow,
  ModalSearchBar,
  OptionContainer,
  OptionList,
  SearchContainer,
  StyledSearchHighlight,
} from "./CategorySearch";

const CategorizableAccountSearch = ({
  initialCategory,
  onChange,
  onKeyDown,
  open = true,
  onClose,
  subset,
}: {
  onKeyDown?: (event: React.KeyboardEvent) => void;
  initialCategory?: CategoryFragment;
  onChange: (val: CategoryFragment) => void;
  open?: boolean;
  subset?: string;
} & Pick<AutocompleteProps<CategoryFragment>, "onClose">) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");

  const { categorizableAccounts, categorizableAccountsByPrimaryCoaKey } = useCategorizableAccounts(
    subset,
    ["category_match", "company_specific_coa_key_match"]
  );

  let value: CategorizableAccount | undefined = undefined;
  if (initialCategory && !!initialCategory.coaKey) {
    value = categorizableAccounts.find((c) => {
      // why need "!"" ?
      return c.coaKeys.includes(initialCategory.coaKey!);
    });
  }

  // TODO share this with ExtendedFilter?
  const miniSearch = useMemo(() => {
    const instance = new MiniSearch<CategorizableAccount>({
      fields: CATEGORIZABLE_ACCOUNT_SEARCHABLE_FIELDS as string[],
      idField: CATEGORIZABLE_ACCOUNT_ID_FIELD,
      searchOptions: {
        boost: { displayName: 2, displayId: 1.5, examples: 1.3, description: 1 },
        prefix: true,
        fuzzy: 0.2,
      },
    });

    instance.addAll(categorizableAccounts);
    return instance;
  }, [categorizableAccounts]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete<CategorizableAccount>({
      id: "select-category",
      options: categorizableAccounts,
      value,
      inputValue,
      onInputChange: (event, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
        }
      },
      openOnFocus: true,
      clearOnEscape: true,
      autoHighlight: true,
      autoSelect: false,
      open,
      onClose,
      getOptionLabel: (val) => val.displayName,
      getOptionKey: (val) => `${val.displayName}-${val.primaryCoaKey}`,
      isOptionEqualToValue: (option, value) => {
        return option.primaryCoaKey === value.primaryCoaKey;
      },
      onChange: (e, newValue) => {
        if (newValue) {
          onChange(newValue.primaryCategory);
          setInputValue("");
        }
      },
      filterOptions: (options, state) => {
        const searchInput = state.inputValue;
        if (!searchInput) {
          return options;
        }

        const exactPhrases = searchInput
          .match(/"(.*?)"/g)
          ?.map((x) => x.toLowerCase().replace(/[^\w ]/g, ""));

        const searchResults = miniSearch.search(searchInput);

        return searchResults
          .map((result) => categorizableAccountsByPrimaryCoaKey[result.id])
          .filter((item) => {
            if (exactPhrases && exactPhrases.length > 0) {
              const fieldValues = CATEGORIZABLE_ACCOUNT_SEARCHABLE_FIELDS.map(
                (key) =>
                  item[key]
                    ?.toString()
                    .toLowerCase()
                    .replace(/[^\w ]/g, "") || ""
              );
              return exactPhrases.every((phrase) =>
                fieldValues.some((value) => value.includes(phrase))
              );
            }

            return item;
          });
      },
    });

  // ?
  const options = groupedOptions as CategorizableAccount[];

  return (
    <Body ref={containerRef}>
      <SearchContainer {...getRootProps()}>
        <ModalSearchBar
          {...getInputProps()}
          onMouseDown={(e) => e.preventDefault()}
          autoFocus
          onKeyDown={onKeyDown}
          size="mini"
          suffix={<Search />}
          clearable
        />

        <OptionContainer>
          {useMemo(
            () =>
              options.length > 0 ? (
                <OptionList {...getListboxProps()}>
                  {options.map((option, index) => {
                    const prefix = `[${capitalize(option.type.replaceAll("_", " "))}] `;
                    const title = prefix + option.displayName;
                    const props = { ...getOptionProps({ option, index }) };
                    return (
                      <CategoryOptionRow id={option.primaryCoaKey} {...props} key={props.key}>
                        <CategoryHeader>
                          <StyledSearchHighlight text={title} query={inputValue} />
                        </CategoryHeader>
                        <CategoryInfoGrid>
                          <CategoryInfoHeader>Code</CategoryInfoHeader>
                          {option?.displayId ? (
                            <CategoryInfoText>{option.displayId}</CategoryInfoText>
                          ) : (
                            "-"
                          )}

                          <CategoryInfoHeader>Description</CategoryInfoHeader>
                          <CategoryInfoText>
                            <StyledSearchHighlight
                              // based on looking at the ledger COA templates
                              // category examples should come from the account anyway
                              // and be the same amonst all the categories for an account
                              text={option.primaryCategory.description || ""}
                              query={inputValue}
                            />
                          </CategoryInfoText>

                          <CategoryInfoHeader>Examples</CategoryInfoHeader>
                          <CategoryInfoText>
                            <StyledSearchHighlight
                              // based on looking at the ledger COA templates
                              // category examples should come from the account anyway
                              // and be the same amonst all the categories for an account
                              text={option.primaryCategory.examples || ""}
                              query={inputValue}
                            />
                          </CategoryInfoText>
                        </CategoryInfoGrid>
                      </CategoryOptionRow>
                    );
                  })}
                </OptionList>
              ) : null,
            [getListboxProps, getOptionProps, inputValue, options]
          )}
        </OptionContainer>
      </SearchContainer>
    </Body>
  );
};

export default CategorizableAccountSearch;
