import React from "react";
import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { KeyboardKey } from "../Onboarding/KeyboardKey";
import { baseRootStyle } from "./RuleCreatedMessage.css";

export const RuleCreatedMessage = () => {
  return (
    <Box className={baseRootStyle}>
      <KeyboardKey letter="E" />
      <Text>Manage this rule</Text>
    </Box>
  );
};
