import React from "react";
import { IntegrationLogo } from "components/integrations/shared/IntegrationLogo";
import { Bank } from "@puzzle/icons";

export const BankLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#2A2A38" circular={circular}>
      <Bank size={18} />
    </IntegrationLogo>
  );
};
