import React from "react";
import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { veColors as colors } from "@puzzle/theme";
import {
  basicContainerStyle,
  containerLargeStyle,
  containerWithNameStyle,
  contentLargeStyle,
  letterLargeStyle,
  letterStyle,
  nameStyle,
  pressTextStyle,
} from "./KeyboardKey.css";

export enum KeyboardKeySize {
  SMALL = "small",
  LARGE = "large",
}

type KeyboardKeyProps = {
  letter: string;
  size?: KeyboardKeySize;
  active?: boolean;
  name?: string;
};

export const KeyboardKey = ({
  letter,
  active,
  name,
  size = KeyboardKeySize.SMALL,
}: KeyboardKeyProps) => {
  return size === KeyboardKeySize.SMALL ? (
    <Box className={name ? containerWithNameStyle : basicContainerStyle}>
      <Text className={letterStyle}>{letter}</Text>
      {name && <Text className={nameStyle}>{name}</Text>}
    </Box>
  ) : (
    <Box
      className={containerLargeStyle}
      css={{ borderColor: active ? colors.white : colors.white04 }}
    >
      <Box className={contentLargeStyle}>
        <Text className={pressTextStyle}>Press</Text>
        <Text className={letterLargeStyle}>{letter}</Text>
        <Box />
      </Box>
    </Box>
  );
};
