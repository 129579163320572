import React, { useMemo } from "react";
import { Avatar } from "@puzzle/ui";
import { useGetTransaction } from "./hooks/useSingleTransaction";

export const AssignedUserAvatar = ({
  id,
  size = "medium",
}: {
  id: string;
  size: "medium" | "small" | "mini";
}) => {
  const { data } = useGetTransaction(id);
  const transaction = data?.transaction;
  const assignment = transaction?.activity?.currentAssignment;

  return useMemo(() => {
    if (!assignment) {
      return null;
    }

    return (
      <Avatar
        size={size}
        user={{
          name: assignment.targetUser.name || undefined,
          email: assignment.targetUser.email!,
        }}
      />
    );
  }, [assignment]);
};
