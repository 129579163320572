import merge from "lodash/merge";
import { CalendarDate } from "@internationalized/date";
import { GroupBy, RangePreset, ReportExportFormat, ReportGroupBy } from "@puzzle/ui";
import { today } from "@puzzle/utils";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { LedgerReportFilterInput, LedgerReportLevel, LedgerView } from "graphql/types";
import { useMemo } from "react";
import { useSetState } from "react-use";
import { Last3MonthsByPeriod } from "./reportPresets";

export type FilterState = {
  start: CalendarDate;
  end: CalendarDate;
  groupBy: ReportGroupBy;
  preset: RangePreset | null;
  accountingBasis: LedgerView;
  taxYear: CalendarDate;
  level: LedgerReportLevel;
  format: ReportExportFormat;
  includeAccountNumbers: boolean;
  decimalTruncate: boolean;
  filter: LedgerReportFilterInput;
};

export type StickyFilters = Partial<
  Omit<FilterState, "taxYear" | "format" | "includeAccountNumbers" | "decimalTruncate">
>;

const DEFAULT_DATE_PRESET = Last3MonthsByPeriod("month");
const DEFAULT_DATE_RANGE = DEFAULT_DATE_PRESET.range!();

export const useReportFilter = (stickyFilters?: StickyFilters) => {
  const { company, timeZone } = useActiveCompany<true>();
  const initialState: FilterState = useMemo(() => {
    const [start, end] = DEFAULT_DATE_RANGE;
    return {
      preset: DEFAULT_DATE_PRESET,
      groupBy: GroupBy.Month,
      start: start,
      end: end,
      accountingBasis: LedgerView.Cash,
      taxYear: today(timeZone).subtract({ years: 1 }),
      level: LedgerReportLevel.Detailed,
      format: ReportExportFormat.EXCEL,
      includeAccountNumbers: true,
      decimalTruncate: false,
      filter: {},
    };
  }, [timeZone]);

  const [filters, setFilters] = useSetState<FilterState>(merge(initialState, stickyFilters));
  const variables = useMemo(
    () => ({
      companyId: company.id,
      view: filters.groupBy,
      start: filters.start,
      end: filters.end,
    }),
    [company, filters.end, filters.start, filters.groupBy]
  );

  return { filters, setFilters, variables };
};
