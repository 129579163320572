import flatten from "lodash/flatten";
import keyBy from "lodash/keyBy";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import { LedgerAccountFragment } from "components/dashboard/Accounting/shared/chartOfAccounts.graphql.generated";
import { CategoryFragment, LedgerAccountType } from "graphql/types";
import { useMemo } from "react";

// RED-1230
// This should all be better, more centralized, better types.
// categorizableAccounts are an aggregation of LedgerAccounts and Categories
// that allows us to support categorization modals and selects directly
// given the updated requirements in the attached ticket
// I don't think this all is very intuitive or great code, but for the
// short term it should get us were we need
// There is talk of more changes to accounts/categories/rules along with
// additional API models that could make this more logical

export type CategorizableAccount = LedgerAccountFragment & {
  categories: CategoryFragment[];

  // for the time being we need to select any of the coa_key
  // options for the account to represent the account
  primaryCategory: CategoryFragment;
  primaryCoaKey: string;

  // for search
  // figure out if this is the best way to handle this
  description: string;
  examples: string;
};

export const CATEGORIZABLE_ACCOUNT_SEARCHABLE_FIELDS: readonly (keyof CategorizableAccount)[] = [
  "displayName",
  "displayId",
  "examples",
  "description",
] as const;

export const CATEGORIZABLE_ACCOUNT_ID_FIELD = "primaryCoaKey";

// Ledger defined ruleTypes as of today
// Todo: type these in the GW
//     system owned: shouldn't be appearing on the UIs
//        - beginning_balance_fallback
//        - category_money_in_fallback
//        - category_money_out_fallback
//        - integration_specific_account
//        - investing_fallback
//     not system owned:
//        - category_match: transactions, invoices, mjes, bills
//        - company_specific_coa_key_match: when companies add a specific account, transactions, invoices, mjes, bills
//        - extended_category_match: just mjes
//        - historical_category_match: historical journal entries only

// todo: update subset and ruleType to be better defined
// ruleType would need to be changed at the GW level ideally
// maybe define as LedgerAccountType at the source
export function useCategorizableAccounts(subset?: string, ruleTypes?: string[]) {
  const { categories: accounts, allCategoriesByPermaKey } = useChartOfAccounts();

  const categorizableAccounts = useMemo(() => {
    if (!allCategoriesByPermaKey) return [];
    const ret1 = accounts
      .map((a) => {
        let cats = flatten(a.coaKeys.map((c) => allCategoriesByPermaKey[c] || [])).filter(
          (c) => !c.deprecated
        );
        if (ruleTypes) {
          cats = cats.filter((c) => c.ruleType && ruleTypes?.includes(c.ruleType));
        }
        if (!cats.length) {
          return undefined;
        }
        return {
          ...a,
          categories: cats,
          primaryCategory: cats[0],
          primaryCoaKey: cats[0].coaKey,
          description: cats[0].description || "",
          examples: cats[0].examples || "",
        } as CategorizableAccount;
      })
      .filter((a) => a);

    // if there are no categories based on the deprecated or ruletype filtering,
    // remove the categorizable account
    let ret2 = ret1.filter((a) => !!a) as CategorizableAccount[];

    if (subset === "expenses") {
      ret2 = ret2?.filter((a) => a.type === LedgerAccountType.Expense);
    } else if (subset === "income") {
      ret2 = ret2?.filter((a) => a.type === LedgerAccountType.Revenue);
    }
    ret2.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    return ret2;
  }, [allCategoriesByPermaKey, accounts, subset, ruleTypes]);

  const categorizableAccountsByDisplayId = useMemo(
    () => keyBy(categorizableAccounts, "displayId"),
    [categorizableAccounts]
  );

  const categorizableAccountsByPrimaryCoaKey = useMemo(
    () => keyBy(categorizableAccounts, "primaryCoaKey"),
    [categorizableAccounts]
  );

  return {
    categorizableAccounts,
    categorizableAccountsByDisplayId,
    categorizableAccountsByPrimaryCoaKey,
  };
}
