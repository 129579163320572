import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function LockTransaction({
  viewBox = "0 0 18 18",
  width = 18,
  height = 18,
  fill = "#F74B75",
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15 3h-2V2h-1v1H6V2H5v1H3c-.55 0-1 .487-1 1.088v10.825c0 .6.45 1.087 1 1.087h12c.55 0 1-.488 1-1.088V4.088C16 3.488 15.55 3 15 3zm0 11.912a.138.138 0 01-.025.088H3.025A.137.137 0 013 14.912V6h12v8.912z"
        clipRule="evenodd"
      ></path>
      <mask id="path-2-inside-1_3113_86455" fill={colors.white}>
        <path
          fillRule="evenodd"
          d="M9.75 7.25a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4zm-.75 7a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9.75 7.25a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4zm-.75 7a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        d="M9.75 7.25h-1 1zm0 4h-1 1zM9 7.5a.25.25 0 01-.25-.25h2A1.75 1.75 0 009 5.5v2zm.25-.25A.25.25 0 019 7.5v-2a1.75 1.75 0 00-1.75 1.75h2zm0 4v-4h-2v4h2zM9 11a.25.25 0 01.25.25h-2c0 .966.784 1.75 1.75 1.75v-2zm-.25.25A.25.25 0 019 11v2a1.75 1.75 0 001.75-1.75h-2zm0-4v4h2v-4h-2zm0 6.25a.25.25 0 01.25-.25v2a1.75 1.75 0 001.75-1.75h-2zm.25.25a.25.25 0 01-.25-.25h2A1.75 1.75 0 009 11.75v2zm.25-.25a.25.25 0 01-.25.25v-2a1.75 1.75 0 00-1.75 1.75h2zM9 13.25a.25.25 0 01.25.25h-2c0 .966.784 1.75 1.75 1.75v-2z"
        mask="url(#path-2-inside-1_3113_86455)"
      ></path>
    </Icon>
  );
}
